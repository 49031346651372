// languages

export const times = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

export const languages = [
  { value: 'en', label: 'EN', fullForm: 'English', shortform: 'english' },
  { value: 'ja', label: 'JA', fullForm: 'Japanese', shortform: 'japanese' },
  {
    value: 'zh_CN',
    label: 'zh_CN',
    fullForm: 'Simplified Chinese',
    shortform: 'simplified chinese',
  },
  {
    value: 'zh_TW',
    label: 'zh_TW',
    fullForm: 'Traditional Chinese',
    shortform: 'traditional chinese',
  },
];

// audience filter item
export const audiencefiltersItem = [
  { trname: 'name', original: 'name' },
  { trname: 'pEmail', original: 'email' },
  { trname: 'mNumber', original: 'number' },
  { trname: 'userId', original: 'id' },
  { trname: 'userTag', original: 'usertag' },
  { trname: 'city', original: 'city' },
];

export const operatorFilters = [
  { trname: 'contains', original: 'contains' },
  { trname: 'doesNotContain', original: 'does not contain' },
  { trname: 'is', original: 'is' },
  { trname: 'isNot', original: 'is not' },
  { trname: 'exists', original: 'exists' },
  { trname: 'doesNotExist', original: 'does not exist' },
];

export const platformItems = [
  {
    value: 'imChat',
    label: 'im_chat',
  },
  {
    value: 'Instagram',
    label: 'instagram',
  },
  {
    value: 'WeChat',
    label: 'weChat',
  },
  {
    value: 'Line',
    label: 'line',
  },
  {
    value: 'WhatsApp',
    label: 'whatsApp',
  },
  {
    value: 'Messenger',
    label: 'messenger',
  },
];

export const messageTypes = [
  { value: 'push_General', label: 'general' },
  { value: 'spOf', label: 'spacial_offer' },
  { value: 'fSeals', label: 'flash_sales' },
];

// product item
export const productType = [
  {
    label: 'general',
    value: 'push_General',
  },
  {
    label: 'order_status',
    value: 'push_Order',
  },
  {
    label: 'product_promotion',
    value: 'push_Promotion',
  },
  {
    label: 'payment',
    value: 'push_Payment',
  },
];
