export const DownloadIcon = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.04778 0.666626C3.94384 0.666626 2.23826 2.40322 2.23826 4.54541C2.23826 4.65328 2.24259 4.7602 2.25111 4.86599C1.11843 5.40783 0.333496 6.57974 0.333496 7.93935C0.333496 9.81377 1.82588 11.3333 3.66683 11.3333H10.8097C12.3876 11.3333 13.6668 10.0308 13.6668 8.4242C13.6668 7.33508 13.0792 6.38663 12.2102 5.88811C12.2287 5.76636 12.2383 5.64177 12.2383 5.51511C12.2383 4.17624 11.1723 3.09087 9.85731 3.09087C9.76581 3.09087 9.6754 3.09615 9.58639 3.10643C9.02513 1.67694 7.65283 0.666626 6.04778 0.666626ZM7.47635 4.54541C7.47635 4.27764 7.26316 4.06057 7.00016 4.06057C6.73717 4.06057 6.52397 4.27764 6.52397 4.54541V7.73852L5.43212 6.62682C5.24615 6.43747 4.94465 6.43747 4.75868 6.62682C4.57272 6.81616 4.57272 7.12315 4.75868 7.3125L6.66345 9.25189C6.84941 9.44123 7.15092 9.44123 7.33688 9.25189L9.24164 7.3125C9.42761 7.12315 9.42761 6.81616 9.24164 6.62682C9.05568 6.43747 8.75417 6.43747 8.56821 6.62682L7.47635 7.73852V4.54541Z"
        fill="white"
      />
    </svg>
  );
};

export const CheckMarkIcon = () => {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.49693 8L0 4.2079L0.874233 3.25988L3.49693 6.10395L9.12577 0L10 0.948025L3.49693 8Z"
        fill="#23AC00"
      />
    </svg>
  );
};

export const DoubleCheckMarkIcon = () => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.61187 8L0 4.24116L0.910959 3.30977L4.52283 7.06861L3.61187 8ZM7.22374 8L3.61187 4.24116L4.50685 3.29314L7.22374 6.12058L13.105 0L14 0.948025L7.22374 8ZM7.22374 4.24116L6.31279 3.30977L9.47717 0.016632L10.3881 0.948025L7.22374 4.24116Z"
        fill="#23AC00"
      />
    </svg>
  );
};

export const AttachmentIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7455 5.25441C17.3396 3.84852 15.0602 3.84852 13.6543 5.25441L5.25432 13.6544C3.84843 15.0603 3.84843 17.3397 5.25432 18.7456C6.65985 20.1511 8.93843 20.1515 10.3444 18.7467C10.3448 18.7463 10.3451 18.7459 10.3455 18.7456L10.9414 18.1457C11.2917 17.7931 11.8616 17.7912 12.2142 18.1415C12.5668 18.4918 12.5687 19.0617 12.2184 19.4143L11.6204 20.0163L11.6183 20.0184C9.50945 22.1272 6.09036 22.1272 3.98153 20.0184C1.87269 17.9095 1.87269 14.4904 3.98153 12.3816L12.3815 3.98162C14.4904 1.87279 17.9094 1.87279 20.0183 3.98162C22.1255 6.08888 22.1271 9.50445 20.023 11.6137L15.8774 15.8775C14.6472 17.1076 12.6527 17.1076 11.4225 15.8774C10.1924 14.6473 10.1924 12.6528 11.4225 11.4226L15.5635 7.28165C15.915 6.93017 16.4848 6.93017 16.8363 7.28165C17.1878 7.63312 17.1878 8.20297 16.8363 8.55444L12.6953 12.6954C12.1681 13.2226 12.1681 14.0774 12.6953 14.6046C13.2212 15.1305 14.073 15.1318 14.6006 14.6085L18.7454 10.3455C20.1513 8.93963 20.1514 6.6603 18.7455 5.25441Z"
        fill="#1677FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7455 5.25441C17.3396 3.84852 15.0602 3.84852 13.6543 5.25441L5.25432 13.6544C3.84843 15.0603 3.84843 17.3397 5.25432 18.7456C6.65985 20.1511 8.93843 20.1515 10.3444 18.7467C10.3448 18.7463 10.3451 18.7459 10.3455 18.7456L10.9414 18.1457C11.2917 17.7931 11.8616 17.7912 12.2142 18.1415C12.5668 18.4918 12.5687 19.0617 12.2184 19.4143L11.6204 20.0163L11.6183 20.0184C9.50945 22.1272 6.09036 22.1272 3.98153 20.0184C1.87269 17.9095 1.87269 14.4904 3.98153 12.3816L12.3815 3.98162C14.4904 1.87279 17.9094 1.87279 20.0183 3.98162C22.1255 6.08888 22.1271 9.50445 20.023 11.6137L15.8774 15.8775C14.6472 17.1076 12.6527 17.1076 11.4225 15.8774C10.1924 14.6473 10.1924 12.6528 11.4225 11.4226L15.5635 7.28165C15.915 6.93017 16.4848 6.93017 16.8363 7.28165C17.1878 7.63312 17.1878 8.20297 16.8363 8.55444L12.6953 12.6954C12.1681 13.2226 12.1681 14.0774 12.6953 14.6046C13.2212 15.1305 14.073 15.1318 14.6006 14.6085L18.7454 10.3455C20.1513 8.93963 20.1514 6.6603 18.7455 5.25441Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export const CreateMessageIconMuted = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="10" fill="#9EA1B5" />
      <path
        d="M14.4843 13.8308C14.2829 13.8022 14.0809 13.8776 13.9474 14.031C13.814 14.1844 13.7673 14.3949 13.8233 14.5903L14.9543 18.531C15.1019 19.0455 15.5724 19.4 16.1077 19.4H21.0001C21.3314 19.4 21.6001 19.6687 21.6001 20C21.6001 20.3314 21.3314 20.6 21.0001 20.6H16.1077C15.5725 20.6 15.1019 20.9545 14.9543 21.469L13.8233 25.4097C13.7673 25.6052 13.814 25.8157 13.9474 25.9691C14.0809 26.1225 14.2829 26.1978 14.4843 26.1693C19.1545 25.5074 23.3768 23.455 26.7187 20.4459C26.8451 20.3321 26.9172 20.1701 26.9172 20C26.9172 19.83 26.8451 19.6679 26.7187 19.5542C23.3768 16.5451 19.1545 14.4927 14.4843 13.8308Z"
        fill="white"
      />
    </svg>
  );
};

export const CreateMessageIconActive = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="10" fill="#1677FF" />
      <path
        d="M14.4843 13.8308C14.2829 13.8022 14.0809 13.8776 13.9474 14.031C13.814 14.1844 13.7673 14.3949 13.8233 14.5903L14.9543 18.531C15.1019 19.0455 15.5724 19.4 16.1077 19.4H21.0001C21.3314 19.4 21.6001 19.6687 21.6001 20C21.6001 20.3314 21.3314 20.6 21.0001 20.6H16.1077C15.5725 20.6 15.1019 20.9545 14.9543 21.469L13.8233 25.4097C13.7673 25.6052 13.814 25.8157 13.9474 25.9691C14.0809 26.1225 14.2829 26.1978 14.4843 26.1693C19.1545 25.5074 23.3768 23.455 26.7187 20.4459C26.8451 20.3321 26.9172 20.1701 26.9172 20C26.9172 19.83 26.8451 19.6679 26.7187 19.5542C23.3768 16.5451 19.1545 14.4927 14.4843 13.8308Z"
        fill="white"
      />
    </svg>
  );
};

export const AlertIcon = () => {
  return (
    <svg
      className="flex-shrink-0 inline w-4 h-4 me-1"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
    </svg>
  );
};

export const DocumentsIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#FFFFFF" />
      <path
        d="M10.8484 7.66675C10.0116 7.66675 9.33325 8.33299 9.33325 9.15484V22.8453C9.33325 23.6672 10.0116 24.3334 10.8484 24.3334H21.1514C21.9882 24.3334 22.6666 23.6672 22.6666 22.8453V16.5953C22.6666 14.9516 21.3099 13.6191 19.6363 13.6191H18.1211C17.2843 13.6191 16.606 12.9529 16.606 12.131V10.6429C16.606 8.99923 15.2493 7.66675 13.5757 7.66675H10.8484Z"
        fill="#C5C8D9"
      />
      <path
        d="M16.7847 7.91747C17.4286 8.64808 17.8181 9.60083 17.8181 10.6429V12.131C17.8181 12.2954 17.9538 12.4287 18.1211 12.4287H19.6363C20.6973 12.4287 21.6674 12.8112 22.4113 13.4436C21.6938 10.746 19.5313 8.62212 16.7847 7.91747Z"
        fill="#C5C8D9"
      />
    </svg>
  );
};

export const PhotosIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#FFFFFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66675 10.5455C7.66675 9.41578 8.46624 8.5 9.45246 8.5H22.5477C23.5339 8.5 24.3334 9.41578 24.3334 10.5455V21.4545C24.3334 22.5842 23.5339 23.5 22.5477 23.5H9.45246C8.46624 23.5 7.66675 22.5842 7.66675 21.4545V10.5455ZM8.85722 19.6915V21.4545C8.85722 21.8311 9.12372 22.1364 9.45246 22.1364H22.5477C22.8764 22.1364 23.1429 21.8311 23.1429 21.4545V19.6915L21.0085 17.2467C20.5436 16.7141 19.7899 16.7141 19.325 17.2467L18.6276 18.0455L19.3972 18.927C19.6296 19.1932 19.6296 19.6249 19.3972 19.8912C19.1647 20.1575 18.7878 20.1575 18.5554 19.8912L14.4609 15.2012C13.996 14.6687 13.2422 14.6687 12.7773 15.2012L8.85722 19.6915ZM16.8929 12.5909C16.8929 12.0261 17.2927 11.5682 17.7858 11.5682C18.2789 11.5682 18.6787 12.0261 18.6787 12.5909C18.6787 13.1557 18.2789 13.6136 17.7858 13.6136C17.2927 13.6136 16.8929 13.1557 16.8929 12.5909Z"
        fill="#C5C8D9"
      />
    </svg>
  );
};

export const UploadIcon = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 10.75L9 5.125M9 5.125L11.5 7.625M9 5.125L6.5 7.625M4.625 13.25C2.55393 13.25 0.875 11.5711 0.875 9.5C0.875 7.83921 1.95463 6.43058 3.45033 5.93766C3.40087 5.67433 3.375 5.40269 3.375 5.125C3.375 2.70875 5.33375 0.75 7.75 0.75C9.77602 0.75 11.4804 2.12717 11.9781 3.99646C12.2212 3.91761 12.4806 3.875 12.75 3.875C14.1307 3.875 15.25 4.99429 15.25 6.375C15.25 6.66512 15.2006 6.94369 15.1097 7.20277C16.2878 7.65038 17.125 8.78992 17.125 10.125C17.125 11.8509 15.7259 13.25 14 13.25H4.625Z"
        stroke="#3F4252"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DeleteIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2144 6.69231L9.93465 13.6154M6.06519 13.6154L5.78547 6.69231M13.8404 4.22351C14.1168 4.26324 14.3922 4.30575 14.6666 4.35099M13.8404 4.22351L12.9775 14.902C12.9047 15.8037 12.1148 16.5 11.1647 16.5H4.83513C3.88506 16.5 3.09517 15.8037 3.0223 14.902L2.15939 4.22351M13.8404 4.22351C12.914 4.09034 11.9769 3.98835 11.0302 3.91871M1.33325 4.35099C1.60766 4.30575 1.88305 4.26324 2.15939 4.22351M2.15939 4.22351C3.0858 4.09034 4.02294 3.98835 4.96962 3.91871M11.0302 3.91871V3.21399C11.0302 2.30679 10.2943 1.54941 9.3418 1.5204C8.89626 1.50684 8.44893 1.5 7.99992 1.5C7.55091 1.5 7.10358 1.50684 6.65803 1.5204C5.7055 1.54941 4.96962 2.30679 4.96962 3.21399V3.91871M11.0302 3.91871C10.0303 3.84515 9.01969 3.80769 7.99992 3.80769C6.98015 3.80769 5.96957 3.84515 4.96962 3.91871"
        stroke="#FF4D4E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DoubleCheckIcon = () => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.61182 8L-4.95911e-05 4.24116L0.910909 3.30977L4.52278 7.06861L3.61182 8ZM7.22369 8L3.61182 4.24116L4.5068 3.29314L7.22369 6.12058L13.105 0L14 0.948025L7.22369 8ZM7.22369 4.24116L6.31274 3.30977L9.47712 0.016632L10.3881 0.948025L7.22369 4.24116Z"
        fill="#23AC00"
      />
    </svg>
  );
};

export const FileIconManager = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#448CF1" />
      <path
        d="M10.8482 7.66675C10.0114 7.66675 9.33301 8.33299 9.33301 9.15484V22.8453C9.33301 23.6672 10.0114 24.3334 10.8482 24.3334H21.1512C21.988 24.3334 22.6663 23.6672 22.6663 22.8453V16.5953C22.6663 14.9516 21.3096 13.6191 19.636 13.6191H18.1209C17.2841 13.6191 16.6057 12.9529 16.6057 12.131V10.6429C16.6057 8.99923 15.249 7.66675 13.5754 7.66675H10.8482Z"
        fill="#F5F6FC"
      />
      <path
        d="M16.7845 7.91747C17.4283 8.64808 17.8179 9.60083 17.8179 10.6429V12.131C17.8179 12.2954 17.9535 12.4287 18.1209 12.4287H19.636C20.6971 12.4287 21.6672 12.8112 22.4111 13.4436C21.6936 10.746 19.5311 8.62212 16.7845 7.91747Z"
        fill="#F5F6FC"
      />
    </svg>
  );
};

export const FileIconUser = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#DEDFE2" />
      <path
        d="M10.8482 7.66675C10.0114 7.66675 9.33301 8.33299 9.33301 9.15484V22.8453C9.33301 23.6672 10.0114 24.3334 10.8482 24.3334H21.1512C21.988 24.3334 22.6663 23.6672 22.6663 22.8453V16.5953C22.6663 14.9516 21.3096 13.6191 19.636 13.6191H18.1209C17.2841 13.6191 16.6057 12.9529 16.6057 12.131V10.6429C16.6057 8.99923 15.249 7.66675 13.5754 7.66675H10.8482Z"
        fill="#676B80"
      />
      <path
        d="M16.7845 7.91747C17.4283 8.64808 17.8179 9.60083 17.8179 10.6429V12.131C17.8179 12.2954 17.9535 12.4287 18.1209 12.4287H19.636C20.6971 12.4287 21.6672 12.8112 22.4111 13.4436C21.6936 10.746 19.5311 8.62212 16.7845 7.91747Z"
        fill="#676B80"
      />
    </svg>
  );
};

export const EmojiIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.182 15.182C13.4246 16.9393 10.5754 16.9393 8.81802 15.182M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM9.75 9.75C9.75 10.1642 9.58211 10.5 9.375 10.5C9.16789 10.5 9 10.1642 9 9.75C9 9.33579 9.16789 9 9.375 9C9.58211 9 9.75 9.33579 9.75 9.75ZM9.375 9.75H9.3825V9.765H9.375V9.75ZM15 9.75C15 10.1642 14.8321 10.5 14.625 10.5C14.4179 10.5 14.25 10.1642 14.25 9.75C14.25 9.33579 14.4179 9 14.625 9C14.8321 9 15 9.33579 15 9.75ZM14.625 9.75H14.6325V9.765H14.625V9.75Z"
        stroke="#1677FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.182 15.182C13.4246 16.9393 10.5754 16.9393 8.81802 15.182M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM9.75 9.75C9.75 10.1642 9.58211 10.5 9.375 10.5C9.16789 10.5 9 10.1642 9 9.75C9 9.33579 9.16789 9 9.375 9C9.58211 9 9.75 9.33579 9.75 9.75ZM9.375 9.75H9.3825V9.765H9.375V9.75ZM15 9.75C15 10.1642 14.8321 10.5 14.625 10.5C14.4179 10.5 14.25 10.1642 14.25 9.75C14.25 9.33579 14.4179 9 14.625 9C14.8321 9 15 9.33579 15 9.75ZM14.625 9.75H14.6325V9.765H14.625V9.75Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
